import { DeleteOutlined } from "@ant-design/icons"
import { getWatermarkURL } from "@utils/path"
import { normalizePath } from "@utils/path"
import { Comment, Divider } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import _map from "lodash/map"
import React from "react"

import { getWindow } from "../../../utils/getWindow"
import styles from "../style.module.less"
import CommentForm from "./CommentForm"

const Comments = ({
  comments,
  affiliatedId,
  affiliatedUsername,
  onFinish,
  onDeleteComment,
  onDeleteReply,
  formName,
  isEssay,
}) => {
  const { user } = JSON.parse(getWindow().localStorage.getItem("authSite"))

  const isPostAuthor = (commentatorId) => {
    return commentatorId === affiliatedUsername
  }
  const renderAuthor = (comment, reply, isReply) => (
    <div className={styles.comment}>
      {renderLinkAuthorName(comment)}
      {isReply
        ? renderDeleteButtom(reply.commentatorId, () =>
            onDeleteReply({
              commentId: checkIsEssay(comment.id, comment.commentId),
              replyId: checkIsEssay(reply.id, reply.idReply),
            })
          )
        : renderDeleteButtom(comment.commentatorId, () =>
            onDeleteComment({
              commentId: checkIsEssay(comment.id, comment.commentId),
            })
          )}
    </div>
  )
  const renderDeleteButtom = (commentatorId, handleDelete) => {
    if (isPostAuthor(user.id) || commentatorId === user.id) {
      return (
        <button
          onClick={handleDelete}
          className="input-reset bg-white bn pa0 pointer"
        >
          <DeleteOutlined className="f16" />
        </button>
      )
    }
    return null
  }
  const renderAvatar = (URLusename, commentatorPhoto, className) => {
    return (
      <Link to={`/perfil/${URLusename}`}>
        <img
          className={className}
          src={`${process.env.S3_IMAGES}/${commentatorPhoto}`}
        />
      </Link>
    )
  }
  const renderContentText = (comment) => (
    <p className={styles.comment__content}>{comment}</p>
  )
  const checkIsEssay = (item1, item2) => {
    return isEssay ? item1 : item2
  }
  const renderLinkAuthorName = (item) => (
    <Link to={`/perfil/${item.commentatorUsername}`}>
      <a
        className={
          isPostAuthor(item.commentatorId)
            ? styles.authorNameComment
            : styles.nameComment
        }
      >
        {item.commentatorNickname || item.commentatorName}
      </a>
    </Link>
  )

  return _map(comments, (comment) => {
    return (
      <React.Fragment key={checkIsEssay(comment.id, comment.commentId)}>
        <div className={cx("comment__context", styles.comment__block)}>
          <Comment
            author={renderAuthor(comment, false)}
            avatar={renderAvatar(
              comment.commentatorUsername,
              comment.commentatorPhoto,
              styles.comment__avatar
            )}
            content={renderContentText(comment.textComment)}
          >
            <div className={cx("toReply", styles.nestedComment)}>
              <CommentForm
                formName={formName}
                onFinish={onFinish}
                size={24}
                commentId={checkIsEssay(comment.id, comment.commentId)}
              />
            </div>
            {_map(comment.replys, (reply) => {
              return (
                <Comment
                  classnames={cx("reply__comment", styles.comment__reply)}
                  key={checkIsEssay(reply.id, reply.idReply)}
                  author={renderAuthor(comment, reply, true)}
                  avatar={renderAvatar(
                    reply.commentatorUsername,
                    reply.commentatorPhoto,
                    styles.comment__reply__avatar
                  )}
                  content={renderContentText(reply.textComment)}
                />
              )
            })}
          </Comment>
        </div>
        <Divider className={styles.commentDivider} />
      </React.Fragment>
    )
  })
}

export default Comments
