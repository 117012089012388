import { useStores } from "@hooks/use-stores"
import React, { useEffect, useState } from "react"
import { Player } from "video-react"

const VideoWatermarkPlayer = React.forwardRef((props, ref) => {
  const [waterMarkUrl, setWatermarkUrl] = useState(null)
  const { post } = useStores()
  const { getCloudinaryWatermarkLink } = post

  useEffect(() => {
    getCloudinaryVideoURL()
  }, [waterMarkUrl])

  const getCloudinaryVideoURL = async () => {
    const videoLink = await getCloudinaryWatermarkLink(props.src)
    setWatermarkUrl(videoLink)
  }

  return <Player ref={ref} {...props} src={waterMarkUrl} />
})

export default VideoWatermarkPlayer
