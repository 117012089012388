import React from "react"
import * as Icon from "react-feather"

import styles from "../style.module.less"

const Footer = (props) => {
  const {
    likes,
    liked,
    handleLikeButton,
    handleCommentsButton,
    handleShareButton,
    totalComments,
  } = props

  const HeartIcon = () =>
    liked ? (
      <img
        className=""
        src={require("@images/icons/heart-full-color.svg")}
        width="24px"
        height="24px"
        alt="Curtidas"
      />
    ) : (
      <img
        className=""
        src={require("@images/icons/heart-line.svg")}
        width="24px"
        height="24px"
        alt="Curtidas"
      />
    )
  const CommentIcon = () => (
    <img
      className="pointer"
      src={require("@images/icons/comment-line.svg")}
      width="24px"
      height="24px"
      alt="Comentários"
    />
  )

  return (
    <div className={styles.footer}>
      <div className={styles.likes__and__comments}>
        <button onClick={handleLikeButton}>
          <HeartIcon />
          <span className={styles.footer__text}>{likes}</span>
        </button>

        <button onClick={handleCommentsButton}>
          <CommentIcon />
          <span className={styles.footer__text}>{totalComments}</span>
        </button>
      </div>

      {props.share ? (
        <Icon name="Shared" className="f24" onClick={handleShareButton} />
      ) : null}
    </div>
  )
}

export default Footer
