import { Icon } from "@components/Icons"
import VideoWatermarkPlayer from "@components/VideoWatermarkPlayer"
import { useDimensions } from "@hooks/use-dimensions"
import { useResize } from "@hooks/use-resize"
import { useStores } from "@hooks/use-stores"
import { getWatermarkURL } from "@utils/path"
import { Divider, Modal } from "antd"
import cx from "classnames"
import React, { useEffect, useRef, useState } from "react"

import Footer from "../PostCardAnonimatta/components/Footer"
import MainCommentForm from "./components/MainCommentForm"
import CommentForm from "./components/CommentForm"
import Comments from "./components/Comments"
import styles from "./style.module.less"
import { useMedia } from "react-use-media"

const getVideoDimensions = ({ screenWidth }) => {
  let videoWidth = 0
  let videoHeight = 0

  if (screenWidth >= 768 && screenWidth < 1024) {
    videoWidth = 0.95 * screenWidth - 335
    videoHeight = 600
  }

  if (screenWidth >= 1024 && screenWidth < 1327) {
    videoWidth = 0.95 * screenWidth - 335
    videoHeight = 600
  }

  if (screenWidth >= 1327) {
    videoWidth = 945
    videoHeight = 600
  }

  return [videoWidth, videoHeight]
}

const PostModal = ({
  visible,
  handleCloseModal,
  getPost,
  item,
  likes,
  liked,
  affiliatedId,
  postId,
  handleLikeButton,
  isEssay,
  comments,
  onComment,
  setComments,
  onDeleteComment,
  onDeleteReply,
  isFavorite,
  getEssayPhotoVideoComments,
  totalComments,
  setTotalComments,
}) => {
  const { authSite, post } = useStores()
  const { user } = authSite
  const { getCloudinaryWatermarkLink } = post

  const [imageWidth, setImageWidth] = useState("100%")
  const [commentsHeight, setCommentsHeight] = useState("")
  const [imageWatermarkUrl, setImageWatermarkUrl] = useState(null)

  const videoRef = useRef(null)
  const imageRef = useRef(null)

  const imageSrc = getWatermarkURL(item.urlImage, user)
  const videoSrc = getWatermarkURL(item.urlVideo, user)

  const isDesktop = useMedia({ minWidth: 1025 })
  const isIpad = useMedia({ minWidth: 767, maxWidth: 768 })

  const [screenWidth] = useResize()

  const [refDesktop] = useDimensions({
    timeout: 1000,
  })

  const [videoWidth, videoHeight] = getVideoDimensions({ screenWidth })

  const getCloudinaryImageURL = async () => {
    const imageLink = await getCloudinaryWatermarkLink(imageSrc)
    setImageWatermarkUrl(imageLink)
  }

  useEffect(() => {
    getCloudinaryImageURL()
  }, [item])

  useEffect(() => {
    const getComments = async () => {
      const {
        data: [post],
      } = await getPost({ postId: item.id })

      if (post) {
        setComments(post.comments)
      }
    }

    const getEssayFavoritesComments = async () => {
      const commentsData = await getEssayPhotoVideoComments(item.photoVideoId)

      setComments(commentsData)
      setTotalComments(
        commentsData.reduce(
          (acc, cur) =>
            acc + 1 + cur.replys.reduce((replyAcc) => replyAcc + 1, 0),
          0
        )
      )
    }

    if (getPost) getComments()

    if (isFavorite) getEssayFavoritesComments()
  }, [])

  if (!item) return null

  const isOnlyText = !item.urlImage && !item.urlVideo

  const modalContentWrapperClasses = cx("flex", {
    "flex-row": !isOnlyText,
    "flex-column": isOnlyText,
  })
  const maxWidth = isOnlyText ? { maxWidth: "500px" } : {}

  useEffect(() => {
    if (imageRef.current) {
      if (imageRef.current.naturalWidth > imageRef.current.naturalHeight) {
        setImageWidth("100%")
      } else {
        setImageWidth("auto")
      }
    }

    if (videoRef.current) {
      setCommentsHeight(videoRef.current.props.height)
    }
  }, [item.urlImage])

  // PEGA A URL DO VIDEO DA AWS E CRIA A CAPA DO VIDEO COM EXTENSAO .JPG
  const videoCover = `${process.env.S3_IMAGES}/${item.urlVideo}`
  const videoCoverUrl =
    videoCover.substr(0, videoCover.lastIndexOf(".")) + ".jpg"

  return (
    <Modal
      className={cx("modalContent", styles.modalContent)}
      style={{ ...maxWidth }}
      visible={visible}
      width={item.urlImage ? "fit-content" : "100%"}
      centered
      footer={null}
      keyboard
      closeIcon={<Icon name="CloseModal" onClick={handleCloseModal} />}
      title={null}
      maskClosable
      destroyOnClose
      closable
      onCancel={handleCloseModal}
    >
      <div
        className={modalContentWrapperClasses}
        style={
          item.urlVideo
            ? isDesktop
              ? { maxHeight: "80vh" }
              : isIpad
              ? { maxHeight: "600px" }
              : { maxHeight: "70vh" }
            : {}
        }
      >
        {!isOnlyText ? (
          <div
            className={cx("overflow-hidden", styles.postSection)}
            style={{
              backgroundColor: "#000000",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {item.urlImage && (
              <img
                src={`${process.env.S3_IMAGES}/${item.urlImage}`}
                className={styles.imageContainer}
                alt={`Imagem do post do afiliado ${item.name}`}
                ref={imageRef}
                style={{ width: imageWidth }}
                onLoad={() => setCommentsHeight(imageRef.current.height)}
              />
            )}

            {item.urlVideo && (
              <video
                className={styles.videoContainer}
                // width="320"
                // height="240"
                width={videoWidth}
                height={videoHeight}
                controls={true}
                poster={videoCoverUrl}
                preload="none"
                playsInline
                loop
                controlsList="nodownload"
              >
                <source
                  src={`${process.env.S3_IMAGES}/${item.urlVideo}`}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        ) : (
          <div
            id="refDesktop"
            className={styles.commentHeader}
            ref={refDesktop}
          >
            <p className="ma0">{item.postText}</p>
          </div>
        )}

        <div
          className={styles.commentSection}
          style={{ maxHeight: commentsHeight }}
        >
          <div className={styles.postCardContainerEssay}>
            <Footer
              postId={item.id}
              affiliatedId={item.affiliatedId}
              likes={likes}
              liked={liked}
              totalComments={totalComments}
              comments={comments}
              handleLikeButton={handleLikeButton}
            />
          </div>
          <Divider className={styles.commentDivider} />

          <div className="overflow-auto flex-grow-1">
            <Comments
              {...item}
              comments={comments}
              onFinish={onComment}
              onDeleteComment={onDeleteComment}
              onDeleteReply={onDeleteReply}
              formName="textComment"
              isEssay={isEssay}
            />
          </div>

          <div className={styles.formModalContent}>
            <MainCommentForm
              placeholder="Comentar"
              formName="textComment"
              onFinish={onComment}
              size={32}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PostModal
