import { SendOutlined } from "@ant-design/icons"
import translate from "@src/i18n"
import { getWatermarkURL } from "@utils/path"
import { Avatar, Button, Form, Input } from "antd"
import { Collapse } from "antd"
import cx from "classnames"
import React from "react"

const { Panel } = Collapse

function callback(key) {
  console.log(key)
}

import { getWindow } from "../../../utils/getWindow"
import styles from "../style.module.less"

const MainCommentForm = ({
  placeholder,
  size,
  formName,
  onFinish,
  commentId,
}) => {
  const { user } = JSON.parse(getWindow().localStorage.getItem("authSite"))
  const profileImageURL = `${process.env.S3_IMAGES}/${
    user.urlProfile || user.urlPhoto
  }`
  const [form] = Form.useForm()

  const handleFinish = (values) => {
    onFinish({ ...values, user, commentId })
    form.resetFields()
  }

  return (
    <div className={"main__comment__form comment__form"}>
      <div className="mr12">
        <Avatar size={size} src={profileImageURL} />
      </div>
      <Form
        form={form}
        onFinish={(values) => handleFinish(values)}
        className="flex-grow-1 comment__main__form"
      >
        <Form.Item
          name={formName}
          className={cx("flex-grow-1", styles.formItemMargin)}
          rules={[{ required: true }]}
        >
          <Input placeholder={placeholder} />
        </Form.Item>

        <Button
          htmlType="submit"
          className={cx(
            " white comment__buttom__submit",
            styles.comment__main__buttom
          )}
        >
          Publicar
        </Button>
      </Form>
    </div>
  )
}

export default MainCommentForm
