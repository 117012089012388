import { SendOutlined } from "@ant-design/icons"
import translate from "@src/i18n"
import { getWatermarkURL } from "@utils/path"
import { Avatar, Button, Form, Input } from "antd"
import { Collapse } from "antd"
import cx from "classnames"
import React from "react"

import { getWindow } from "../../../utils/getWindow"

const { Panel } = Collapse

function callback(key) {
  console.log(key)
}

import styles from "../style.module.less"

const CommentForm = ({ placeholder, size, formName, onFinish, commentId }) => {
  const { user } = JSON.parse(getWindow().localStorage.getItem("authSite"))

  const [form] = Form.useForm()

  const handleFinish = (values) => {
    onFinish({ ...values, user, commentId })
    form.resetFields()
  }

  return (
    <div className="flex-grow-1 comment__form">
      <Collapse className="commentArea" ghost={true} bordered={false} accordion>
        <Panel
          showArrow={false}
          className="header__comment-act"
          header="Responder"
          key="1"
        >
          <Form
            form={form}
            onFinish={(values) => handleFinish(values)}
            className="form-reply-comment"
          >
            <Form.Item
              name={formName}
              className={cx("flex-grow-1", styles.formItemMargin)}
              rules={[
                { required: true, message: translate("commentRequired") },
              ]}
            >
              <Input placeholder={placeholder} />
            </Form.Item>
            <Button
              htmlType="submit"
              className={cx("btn-reply", styles.btnReply)}
            >
              Publicar
            </Button>
          </Form>
        </Panel>
      </Collapse>
    </div>
  )
}

export default CommentForm
