import { useEffect, useState } from "react"

const getWidth = () => (typeof window !== "undefined" ? window.innerWidth : 0)

const getHeight = () => (typeof window !== "undefined" ? window.innerHeight : 0)

export const useResize = () => {
  let [width, setWidth] = useState(getWidth())
  let [height, setHeight] = useState(getHeight())

  useEffect(() => {
    let timeoutId = null

    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setWidth(getWidth()), 150)
      timeoutId = setTimeout(() => setHeight(getHeight()), 150)
    }

    window.addEventListener("resize", resizeListener)

    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return [width, height]
}
